
import {
    useAccount,
    useEnsAddress,
} from "wagmi";


import getENV from './util/getENV.js';
import { Link, Navigate, useParams } from 'react-router-dom';
import Header from './Header.js';
import Collection from './Collection.js';
import useSkelephunks from './hooks/useSkelephunks.js';
import defined from './util/defined.js';
import { Web3Button, useWeb3Modal } from '@web3modal/wagmi/react';
import Grid from "./Grid.js";
export default function Tokens() {
    const { modules, chainId } = getENV();
    const { open, isConnected } = useAccount();
    const { tokens } = useParams();
    const { skelephunks } = useSkelephunks();
    const tokensList = tokens.split(',')
    const balance = tokensList.length;
    return (<div className="landing" data-w-id="31a62934-7dee-bf92-5793-1bf374967db7">
        <Header subtitle="The Collection" />
        <div id="inventory" className="inventory wf-section">
            {<>
                {defined(balance) && <h3 className="skeleton-keys">
                    {balance} Skelephunk{balance == 1 ? '' : 's'}
                </h3>}
                <Grid collection={skelephunks} tokenIDs={tokensList} />
                <div className="cta-info eth">reveal random new skelephunks</div>
                <div className="top">
                    <Link onClick={() => { if (!isConnected) open(); }} to={'/mint'} className="cta">MINT YOURS NOW</Link>
                </div>
            </>}
        </div>
    </div>)
}