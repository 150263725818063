import equiv from "./util/equiv";

export default function TxButton({ disabled, label, loading, onClick, status }) {
    return (
        <button
            disabled={disabled && (equiv(status, 'loading')||equiv(status, 'signing'))}
            className={`action w-button catacombs ${status}`}
            onClick={onClick}
        >
            {equiv(status, 'signing') ? <>✍️ please sign the transaction</>
                : equiv(status, 'loading') ? loading
                    : label}
        </button>
    )
}