import { useState } from "react";
import NFTOverlay from "./NFTOverlay.js";
import Thumbnail from "./Thumbnail.js";
import useCatacombs from "./hooks/useCatacombs.js";
import getENV from "./util/getENV.js";
import { useAccount, useContractWrite, usePrepareTransactionRequest, useWaitForTransactionReceipt } from "wagmi";
import useObituary from "./hooks/useObituary.js";
import { Link } from "react-router-dom";
import defined from "./util/defined.js";
import equiv from "./util/equiv.js";
import TxInputButton from "./TxInputButton.js";
import TxButton from "./TxButton.js";

export default function Headshot() {
    const { override, testnet, wagmi: { mainnetChainID: mainnet }, modules } = getENV();
    const account = useAccount();
    let isConnected, wallet
    if (override) {
        isConnected = true;
        wallet = override
    } else {
        isConnected = account.isConnected;
        wallet = account.address;
    }
    const { obituary } = useObituary();
    const tag = obituary.getName(wallet);
    const tokenID = obituary.getToken(wallet);
    const lockedTime = obituary.getTimestamp(wallet);
    const bio = obituary.getProfileItem(wallet, 'bio')
    const twitter = obituary.getProfileItem(wallet, 'twitter')
    const instagram = obituary.getProfileItem(wallet, 'instagram')
    const discord = obituary.getProfileItem(wallet, 'discord')

    const { catacombs } = useCatacombs();
    const info = catacombs.getTokenInfo(tokenID);
    const meta = catacombs.getMetadata(tokenID);
    const ownerIsYou = isConnected && defined(info.owner, wallet) ? equiv(info.owner, wallet) : undefined;
    const [activeTokenID, setActiveTokenID] = useState();
    const closeOverlay = () => {
        setActiveTokenID(null);
    }
    const [name, setName] = useState('');
    const nameIsAvailable = name?.length > 0 && obituary.nameAvailable(name);
    const hasIdentity = obituary.hasIdentity(wallet);
    const enableNameToken = ownerIsYou && (name.length > 0 && nameIsAvailable) && defined(wallet, modules.obituary.contract.address, tokenID);
    const { status: nameStatus, error: nameError, write: nameToken } = obituary.setMyName(name).config({ enabled: enableNameToken });
    const handleNameInput = (name) => {
        setName(name?.toLowerCase().replace(' ', '-').replace(/^[0-9-]/, '').replace(/[^a-z0-9-]/, '').replace(/-{2,}/, '-'));
        console.log(name);
    }


    const [newBio, setNewBio] = useState(bio);
    const enableSetBio = ownerIsYou && hasIdentity && defined(wallet, modules.obituary.contract.address) && (defined(bio) ? !equiv(bio, newBio) : true) && newBio?.length > 0
    const { status: bioStatus, error: bioError, write: setBio } = obituary.setMyProfileItem('bio', newBio).config({ enabled: enableSetBio });
    const handleBioInput = (bio) => {
        setNewBio(bio);//?.substr(0, 32));// toLowerCase().replace(' ', '-').replace(/^[0-9-]/, '').replace(/[^a-z0-9-]/, '').replace(/-{2,}/, '-'));
        console.log(`${newBio}->${bio}`);
    }
    const [newTwitter, setNewTwitter] = useState(twitter);
    const enableSetTwitter = ownerIsYou && hasIdentity && defined(wallet, modules.obituary.contract.address) && (defined(twitter) ? !equiv(twitter, newTwitter) : true) && newTwitter?.length >= 5 && newTwitter?.length <= 16;
    const { status: twitterStatus, error: twitterError, write: setTwitter } = obituary.setMyProfileItem('twitter', newTwitter).config({ enabled: enableSetTwitter });
    const handleTwitterInput = (twitter) => {
        setNewTwitter(`@${twitter.substr(0, 14).toLowerCase().replace('@', '').replace(/[^a-z0-9_]/, '')}`);
        console.log(`${newBio}->${bio}`);
    }
    const [newDiscord, setNewDiscord] = useState(discord);
    const enableSetDiscord = ownerIsYou && hasIdentity && defined(wallet, modules.obituary.contract.address) && (defined(discord) ? !equiv(discord, newDiscord) : true) && newDiscord?.length > 1
    const { status: discordStatus, error: discordError, write: setDiscord } = obituary.setMyProfileItem('discord', newDiscord).config({ enabled: enableSetDiscord });
    const handleDiscordInput = (discord) => {
        setNewDiscord(discord?.substr(0, 32));// toLowerCase().replace(' ', '-').replace(/^[0-9-]/, '').replace(/[^a-z0-9-]/, '').replace(/-{2,}/, '-'));
        console.log(`${newDiscord}->${discord}`);
    }


    //DESTROY PROFILE 

    const { write: destroyProfile, status: destroyStatus, error: destroyError } = obituary.destroyMyIdentity();

    return (<>
        {!!activeTokenID && <NFTOverlay closeFunction={closeOverlay} collection={catacombs} tokenID={tokenID} />}
        <div className="profile" style={{ border: '1px solid rgba(3, 255, 255, .5)' }} >
            <div className="user">

                <div style={{ color: 'rgb(3, 255, 255)' }} className="key-name">
                    {tag.toUpperCase()}
                </div>
                <div className="mint-info" style={{alignItems:'center'}}>
                    <TxInputButton
                        status={bioStatus}
                        label={bio?.length > 0 ? <>Bio: {bio}</> : <>Write a short bio</>}
                        onInput={handleBioInput}
                        placeholder={`Write your ${bio?.length > 0 ? 'new ' : ''}bio`}
                        value={newBio}
                        loading="setting your bio...."
                        onSubmit={setBio}
                        submitLabel="Apply"
                        submitEnabled={enableSetBio}
                    />
                </div>
                <div className="item">
                    <div className="token" style={{ display: 'flex', flexDirection: `${info.direction == 'punk' ? 'row' : 'row-reverse'}` }} >
                        <Thumbnail collection={catacombs} tokenID={tokenID} label={false} onClick={setActiveTokenID} />

                        <div className="information">
                            <TxInputButton
                                status={twitterStatus}
                                label={twitter?.length > 0 ? <>Twitter: {twitter}</> : <>👤 Add Twitter / X</>}
                                loading={<>👤 Setting Twitter...</>}
                                onInput={handleTwitterInput}
                                placeholder={`Enter your Twitter`}
                                value={newTwitter}
                                onSubmit={setTwitter}
                                submitLabel="Set"
                                submitEnabled={enableSetTwitter}
                            />
                            {/* <TxInputButton
                                status={instagramStatus}
                                label={instagram?.length > 0 ? <>Instagram: {instagram}</> : <>👤 Add Instagram</>}
                                loading={<>👤 Setting your Insta..</>}
                                onInput={handleInstagramInput}
                                placeholder={`Enter your Insta`}
                                value={newInstagram}
                                onSubmit={setInstagram}
                                submitLabel="Set"
                                submitEnabled={enableSetInstagram}
                                on
                            /> */}
                            <TxInputButton
                                status={discordStatus}
                                label={discord?.length > 0 ? <>Discord: {discord}</> : <>👤 Add Discord</>}
                                loading={<>👤 Setting your discord..</>}
                                onInput={handleDiscordInput}
                                placeholder={`Enter your discord`}
                                value={newDiscord}
                                onSubmit={setDiscord}
                                submitLabel="Set"
                                submitEnabled={enableSetDiscord}
                            />

                            <TxInputButton
                                status={discordStatus}
                                label={discord?.length > 0 ? <>Discord: {discord}</> : <>👤 Add Discord</>}
                                loading={<>👤 Setting your discord..</>}
                                onInput={handleDiscordInput}
                                placeholder={`Enter your discord`}
                                value={newDiscord}
                                onSubmit={setDiscord}
                                submitLabel="Set"
                                submitEnabled={enableSetDiscord}
                            />
                        </div>
                    </div>
                </div>

                <div className="mint-info welcome"> Time of Death {new Date(lockedTime * 1000).toLocaleString()}</div>

                <div style={{
                    color: 'rgb(3, 255, 255)'
                }} className="modify-note" >to switch pfp, open the token you want from <Link className="info-link" to="/inventory">your collection</Link></div>
                <div className="profile-actions">
                    <TxInputButton
                        status={nameStatus}
                        label={<>👤 Choose a different name</>}
                        loading={<>👤 Updating your name..</>}
                        onInput={handleNameInput}
                        placeholder={`Write a new name`}
                        value={name}
                        onSubmit={nameToken}
                        submitLabel="Update"
                        submitEnabled={nameIsAvailable}
                    />

                    <TxButton
                        status={destroyStatus}
                        onClick={destroyProfile}
                        loading={<>🚫 destroying your identity.. </>}
                        label={<>🚫 Destroy your Identity</>}
                    />
                </div>
            </div>
        </div>
    </>)
}