import GAD from "../util/GAD";
import defined from "../util/defined";
import getENV from "../util/getENV";
import useNFTCollection from "./useNFTCollection";
import useSkelephunks from "./useSkelephunks";

export default function useCatacombs() {
    const { modules: { catacombs: { contract } } } = getENV();
    const { skelephunks } = useSkelephunks(contract);
    return { ...skelephunks, catacombs: skelephunks, ext: skelephunks }
}