import defined from "./defined";

export default function equiv(...args) {
    if (args.length < 2) throw new Error(`Not enough arguments to test for equivalency (${args.length})`)
    let ret = true;
    let val = args[0];
    args.forEach((arg, i) => {
        ret = ret && defined(arg) && arg === val;
        val = arg;
    })
    return ret
}