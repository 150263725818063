import { readContract, watchBlockNumber, prepareWriteContract, writeContract, waitForTransactionReceipt, watchContractEvent } from '@wagmi/core'
import defined from '../util/defined';
import { useEffect, useMemo, useState } from "react";

import equiv from "../util/equiv.js";
import getENV from '../util/getENV.js';
import { resolveBreakpointValues } from '@mui/system/breakpoints.js';
import useRedraw from './useRedraw.js';

const out = (result, output) => {
    if (!defined(result)) return
    let Cast;
    if (output.type.indexOf('int') + 1) {
        Cast = Number;
    } else {
        switch (output.type) {
            case 'address':
                Cast = String;
                break;
            case 'bool':
                Cast = Boolean;
                break;
            default:
                Cast = String;
                break;
        }
    }

    return Cast ? Cast(result) : result;
}
const contracts = {};
let instances = {};
const transactions = {};
export default function useSmartContract({ address, abi }) {
    const { redraw } = useRedraw();
    // console.log(`useSmartContract ${address}`);
    const { wagmi: { config: wagmiConfig } } = getENV();
    instances[address] = instances[address] || 0;
    // console.log(`there are currently ${instances[address]} instances`);
    const instance = useMemo(() => instances[address]++, []);
    const [reading, setReading] = useState(0);

    //const instance = 1
    let contract = contracts[address] || {//one contract object per address
        address,
        abi: {},
        reads: [],//reads[instance] -- separate read queue per instance 
        results: {},//results[functionName][args-concat] -- shared results queue
        functions: [],//contract[instance] -- separate set of methods per instance, to create reads in correct queue
        refresh: [],//contract[instance] -- refresh flag per instance, to create reads in correct queue
        events: false,
        transactions: {}
    };
    if (!defined(contract.functions[instance])) {
        // console.log(`creating functions for ${address}[${instance}]`)
        contract.functions[instance] = {};
        // console.log(`parsing abi for ${address}[${instance}]`)
        abi.forEach(({ name, type, stateMutability, inputs, outputs }) => {
            switch (type) {
                case 'function':
                    const inlen = inputs.length;
                    contract.reads[instance] = contract.reads[instance] || []
                    if (!defined(contract.functions[instance][name])) {
                        contract.functions[instance][name] = (...args) => {
                            // if (args.length !== inlen) {

                            //     throw new RangeError(`Incorrect number of arguments passed to function ${name}. (Expecting ${inputs.length})`)
                            // }
                            const key = args.join(',');
                            switch (stateMutability) {
                                case 'view':
                                case 'pure':
                                    if (inputs.length > 0 && !defined(...args)) {
                                        return
                                    }
                                    contract.results[name] = contract.results[name] || {};
                                    const read = { address, abi, functionName: name, args };
                                    let result = contract.results[name][key];
                                    result = contract.results[name][key] || {}
                                    contract.reads[instance].push(read);
                                    readContract(wagmiConfig, read).then((value) => {
                                        if (contract.results[name][key]?.value !== value) {
                                            contract.results[name][key] = { value }
                                            contract.refresh[instance] = true;
                                        }
                                    }).catch((error) => {
                                        // console.log(`smart contract read error at ${name}(${key}): ${error}`) fail quietly
                                        // contract.refresh[instance] = true; dont refresh, no new value
                                    }).finally(() => {
                                        contract.reads[instance].splice(contract.reads[instance].indexOf(read), 1)
                                        if (contract.reads[instance].length === 0 && contract.refresh) {
                                            contract.refresh[instance] = false;
                                            setReading(Math.random());
                                        }
                                    });
                                    const output = out(result.value, outputs[0]);
                                    return output
                                case 'payable':
                                case 'nonpayable':
                                    contract.transactions[name] = contract.transactions[name] || {};
                                    const write = {
                                        abi,
                                        address,
                                        functionName: name,
                                        args
                                    }
                                    let transaction = contract.transactions[name][key] || { write, status: 'idle' };
                                    const disabled = {
                                        ...transaction,
                                        write: () => {
                                            console.log(`${name}(${key}) disabled`);
                                            return transaction
                                        },
                                        config: () => {
                                            console.log(`${name}(${key}) disabled`);
                                            return transaction
                                        }
                                    }
                                    transaction.write = () => {
                                        writeContract(wagmiConfig, write).then(hash => {
                                            waitForTransactionReceipt(wagmiConfig, { hash, chainId: wagmiConfig.chainId }).then(() => {
                                                contract.transactions[name][key] = { ...transaction, status: 'success' };
                                                redraw();
                                            });
                                            contract.transactions[name][key] = { ...transaction, hash, status: 'loading' };
                                            redraw();
                                        }).catch(e => {
                                            e.reason = e.shortMessage.split(':\n').pop();
                                            contract.transactions[name][key] = { ...transaction, status: 'error', error: e };
                                            console.log(`tx error:${e.reason}`);
                                            redraw();
                                        })
                                        contract.transactions[name][key] = { ...transaction, status: 'signing' };
                                        redraw(true);
                                    };
                                    if (inputs.length > 0 && !defined(...args)) {
                                        return disabled
                                    }
                                    transaction.config = ({ enabled, gas, value, onSuccess }) => {
                                        if (equiv(enabled, false)) return disabled
                                        write.gas = gas;
                                        write.value = value;
                                        write.onSuccess = onSuccess
                                        return transaction
                                    }
                                    contract.transactions[name][key] = transaction;
                                    return transaction
                            }
                        }
                    } //else console.log(`contract method ${name} exists on ${address}[${instance}]`);
                    break;
                case 'event':// do this once only
                    if (!contract.events) {
                        watchContractEvent(wagmiConfig, {
                            address,
                            abi,
                            eventName: name,
                            onLogs(logs) {
                                console.log(`>>>EVENT: ${name} @ ${address.substring(0, 4)}..${address.substring(address.length - 2, 2)}`)
                                setReading(Math.random());
                            },
                        })
                        contract.events = true;
                    }
            }
        });
    }
    contracts[address] = contract;
    return { /*instance, */ ...contract, clear: () => { contract.results = {}; }, contract: contract.functions[instance], ext: contract }
}