import PFP from './PFP.js';
import { useEffect, useState } from 'react';
import { useEnsName, useAccount, useContractWrite, usePrepareTransactionRequest, useWaitForTransactionReceipt, useEnsAddress, useSendTransaction, usePrepareSendTransaction, useWriteContract } from "wagmi";
import getENV from "./util/getENV.js";
import GAD from "./util/GAD.js";
import useClipboard from "react-use-clipboard"
import { ethers } from 'ethers';
import useSkelephunks from './hooks/useSkelephunks.js';
import defined from './util/defined.js';
import equiv from "./util/equiv.js";
import ConnectButton from './ConnectButton.js';
import useObituary from './hooks/useObituary.js';
import { mainnet } from 'viem/chains';
import useGraveyard from './hooks/useGraveyard.js';
import { Link } from 'react-router-dom';
import TxInputButton from './TxInputButton.js';
import TxButton from './TxButton.js';

export default function SKELE({ tokenID, closeFunction }) {

    const { override, opensea, modules, wagmi: { config: wagmiConfig } } = getENV();
    const { skelephunks } = useSkelephunks();
    const { graveyard } = useGraveyard();
    const { obituary } = useObituary();

    const [linkCopied, setLinkCopied] = useClipboard(`https://app.skelephunks.com/token/${tokenID}`, {
        successDuration: 3000,
    });
    const meta = skelephunks.getMetadata(tokenID);
    const info = skelephunks.getTokenInfo(tokenID);
    const [gender, setGender] = useState();
    const [direction, setDirection] = useState();
    useEffect(() => {
        if (defined(meta?.traits)) {
            if (!defined(gender)) {
                setGender(meta.traits.Gender.toLowerCase());
            }
            if (!defined(direction)) {
                setDirection(meta.traits.Direction.toLowerCase());
            }
        }
    })
    const account = useAccount();
    let isConnected, wallet
    if (override) {
        isConnected = true;
        wallet = override
    } else {
        isConnected = account.isConnected;
        wallet = account.address;
    }
    const [sending, setSending] = useState();
    const [staking, setStaking] = useState();
    //GRAVEYARD
    const buried = equiv(info.owner, modules.graveyard.contract.address);
    const locked = equiv(info.owner, modules.catacombs.contract.address);
    const isReserved = graveyard.isReserved(tokenID) || false;
    const tokenReserver = graveyard.reserverOf(tokenID) || "";
    const graveyardOwner = graveyard.owner() || 0;
    const allowReservations = graveyard.allowReservations() || false;
    const allowPurchase = graveyard.allowPurchasing() || false;
    const purchasePrice = graveyard.purchasePrice();
    const graveyardSnapshot = graveyard.snapshot() || 0;
    const useSnapshot = graveyard.useSnapshot() || false;
    const remainingRemints = graveyard.remainingRemintsForWallet(wallet) || 0;
    const remintsLeft = graveyard.remainingRemints() || 0;
    const reservedToken = graveyard.reservationForWallet(wallet) || 0;
    const mintedBeforeSnapshot = useSnapshot && (info.timestamp < graveyardSnapshot);
    const etherPrice = !defined(purchasePrice) ? '--' : ethers.utils.formatEther(`${purchasePrice}`);
    const donation = equiv(graveyardOwner, wallet);

    //IDENTIFIERS
    const { data: minterName } = useEnsName({ config: wagmiConfig, address: info.minter });
    const { data: ownerName } = useEnsName({ config: wagmiConfig, address: info.owner });
    const { data: reserverName } = useEnsName({ config: wagmiConfig, address: tokenReserver });
    const mintedAt = defined(info.timestamp) ? new Date(info.timestamp * 1000).toLocaleString() : 'onto the chain';
    const minterIsOwner = equiv(info.minter, info?.owner);
    const minterIsYou = equiv(info.minter, wallet);
    const ownerIsYou = isConnected && defined(info.owner, wallet) ? equiv(info.owner, wallet) : undefined;
    const shortMinter = `Anon  (${info.minter?.substring(0, 13)})`;
    const shortOwner = `Anon  (${info.owner?.substring(0, 13)})`;
    const changed = defined(gender, direction, info) && (!equiv(gender, info.gender) || !equiv(direction, info.direction));
    const reserverIsYou = equiv(tokenReserver, wallet);
    const shortReserver = `Anon (${tokenReserver?.substring(0, 13)})`;
    const reserver = reserverIsYou ? 'you (click to cancel)' : reserverName ? reserverName : shortReserver;
    //TRAITS
    const traitsJSX = meta?.attributes?.map((t) => {
        return (
            <div className="trait" key={t.trait_type}>
                <div className="trait-type">{t.trait_type}:</div>
                <div className="value">{t.value}</div>
            </div>
        )
    });
    //ACTIONS
    //GAD
    const { write: setGenderAndDirection, status: gadStatus, error: gadError } = skelephunks.setGenderAndDirection(tokenID, ...GAD.toArgs(gender, direction));

    // SEN TO FREN
    const [sendAddress, setSendAddress] = useState('');
    const validAddress = sendAddress?.match(/^0x[a-fA-F0-9]{40}$/)?.[0];
    const { data: ensAddress, error: ensError } = useEnsAddress({ config: wagmiConfig, name: validAddress ? 'deadbeef' : sendAddress, chainId: mainnet.id });
    const tagAddress = obituary.getAddress(sendAddress);
    const toAddress = equiv(validAddress, sendAddress) ? sendAddress
        : defined(tagAddress) ? tagAddress
            : defined(ensAddress) ? ensAddress
                : wallet;
    const readyToSend = ownerIsYou && defined(wallet, toAddress, tokenID) && toAddress !== wallet;

    const { status: sendStatus, error: sendError, write: sendGift } = skelephunks.safeTransferFrom(wallet, toAddress, tokenID);
    const startGifting = () => {
        setSending(true);
    }
    const submitGift = () => {
        sendGift();
        setSending(false);
    }


    //BURY TOKEN

    const readyToBury = ownerIsYou && defined(wallet, toAddress, tokenID);
    const { status: buryStatus, error: buryError, write: buryToken } = skelephunks.safeTransferFrom(wallet, modules.graveyard.contract.address, tokenID)

    //GRAVEYARD
    //RESERVE TOKEN

    const { status: reserveStatus, hash: reserveHash, write: reserveToken } = graveyard.reserveToken(tokenID)

    //CANCEL RESERVATION

    const { status: cancelStatus, hash: cancelHash, write: cancelReservation } = graveyard.clearMyReservation(tokenID)

    //PURCHASE

    const { status: purchaseStatus, hash: purchaseHash, write: buyToken } = graveyard.buyBuriedSkelephunk(tokenID).config({ value: `${purchasePrice}` })
    const handleAddressInput = e => setSendAddress(e.target.value);

    //SEND TO CATACOMBS
    const { status: lockStatus, write: lockToken } = skelephunks.safeTransferFrom(wallet, modules.catacombs.contract.address, tokenID);

    //OBITUARY
    const [tag, setTag] = useState('');
    const nameIsAvailable = tag?.length > 0 && obituary.nameAvailable(tag);
    const tagBytes = ethers.utils.formatBytes32String(tag);
    const hasIdenity = obituary.hasIdentity(wallet);

    const enableStakeToken = ownerIsYou && (hasIdenity || nameIsAvailable) && defined(wallet, modules.obituary.contract.address, tokenID);

    const { status: stakeStatus, error: stakeError, write: stakeToken } = skelephunks.safeTransferFrom(wallet, modules.obituary.contract.address, tokenID, tagBytes);
    const { status: tokenStatus, error: tokenError, write: setToken } = skelephunks.safeTransferFrom(wallet, modules.obituary.contract.address, tokenID);

    const handleNameInput = (input) => {
        setTag(input?.toLowerCase().replace(' ', '-').replace(/^[0-9-]/, '').replace(/[^a-z0-9-]/, '').replace(/-{2,}/, '-'));
    }
    const loading = -1 < [sendStatus, gadStatus, buryStatus, lockStatus, reserveStatus, purchaseStatus].indexOf('loading');
    return (
        <div id={`token${tokenID}`} className="nft">
            {defined(tokenID) && <h1 className={`token-name`}>
                {meta?.name || `SKELE #${tokenID}`}
            </h1>}
            <div className="token">
                <div className="jpeg">
                    {defined(meta?.image, gender, direction) ? <>
                        <PFP
                            busy={equiv(gadStatus, 'loading') || equiv(sendStatus, 'loading')}
                            direction={direction}
                            setDirection={setDirection}
                            skelephunks={skelephunks}
                            tokenID={tokenID}
                            gender={gender}
                            setGender={setGender}
                        />
                        {ownerIsYou && <>
                            {changed ?
                                <button
                                    disabled={equiv(gadStatus, 'loading') || equiv(sendStatus, 'loading')}
                                    className="cta w-button update-token"
                                    onClick={setGenderAndDirection}
                                >
                                    {equiv(gadStatus, 'error') ? 'TRY AGAIN'
                                        : equiv(gadStatus, 'loading') ? <>UPDATING..</>
                                            : 'UPDATE TOKEN'}
                                </button>
                                : <div className="modify-note">click above to see pfp variants</div>
                            }
                            {hasIdenity ?
                                <TxButton
                                    disabled={loading}
                                    onClick={setToken}
                                    loading={<>Setting your PFP..</>}
                                    label={<>👤 Make this your PFP</>}
                                />
                                :
                                <TxInputButton
                                    label={<>👤 Create Profile from this PFP</>}
                                    value={tag}
                                    onInput={handleNameInput}
                                    placeholder="Choose a Name"
                                    loading={<>Creating your Profile..</>}
                                    submitEnabled={nameIsAvailable}
                                    onSubmit={stakeToken}
                                    submitLabel={<>claim</>}
                                />
                            }
                        </>}
                    </> : <div id="pfpSpinner" className="spinner"><img src="/images/spinner.gif" /></div>}
                    </div>
                    {defined(traitsJSX) || defined(info.timestamp) ? <div className="information">
                        <div className="metadata">
                            <div className="traits">
                                {defined(traitsJSX) ? <>{traitsJSX}</>
                                    : <div className="welcome">
                                        <div>loading token metadata...</div>
                                        <div className="spinner">
                                            <div>
                                                <img src="/images/spinny.gif" />
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                            <div className="token-info"> {defined(info.timestamp) ? <>
                                <div className="minted-on">
                                    <div>Minted {mintedAt}</div>
                                </div>
                                <div className="minted-by">
                                    <div> by {
                                        minterIsYou ? <span className={`${minterIsOwner ? 'identity' : ''}`}>you</span>
                                            : <Link to={`/wallet/${minterName ? minterName : info.minter}`} className="free">{minterName ? minterName
                                                : shortMinter}</Link>
                                    }</div>
                                </div>
                                {!minterIsOwner &&
                                    <div className="minted-by">
                                        <div>{
                                            buried ? <>buried in <Link to='/graveyard' className="yours">the graveyard</Link></>
                                                : locked ? <>locked in <span className="sealed-at">the catacombs</span></>
                                                    : ownerIsYou ? <span className="identity">yours</span>
                                                        : <>owned by <span className="paid">{ownerName ? ownerName
                                                            : shortOwner}</span> </>
                                        }</div>
                                    </div>
                                }

                            </>

                                : <div className="welcome">
                                    <div>loading token info...</div>
                                    <div>
                                        <img src="/images/spinny.gif" />
                                    </div>
                                </div>}</div>
                        </div >
                        <button
                            className="action catacombs"
                            onClick={() => window.open(opensea.token(modules.skelephunks.contract.address, tokenID))}
                        >
                            view on opensea
                        </button>
                        <button
                            className="action catacombs"
                            onClick={() => setLinkCopied(true)}
                        >
                            {!linkCopied ? 'copy public link' : 'link copied to clipboard'}
                        </button>
                        {isConnected ? <>
                            {ownerIsYou && <>
                                <div className="send-widget">
                                    <TxInputButton
                                        label="sen to a fren (invite)"
                                        disabled={loading}
                                        placeholder="0x or .eth"
                                        loadMessage={`sending to ${sendAddress.substring(0, 13)}..`}
                                        value={sendAddress}
                                        onInput={setSendAddress}
                                        onSubmit={submitGift}
                                        status={sendStatus}
                                        submitEnabled={toAddress !== wallet && readyToSend}
                                        submitLabel="send"
                                    />
                                </div>
                                {(donation || (0 < remainingRemints && mintedBeforeSnapshot)) && <><TxButton
                                    onClick={buryToken}
                                    label={
                                        donation ? <>🪦 donate to graveyard</>
                                            : <>🪦 Bury to swap for {reservedToken ? `#${reservedToken}`
                                                : remainingRemints ? 'new mint'
                                                    : 'random buried token'}</>
                                    }
                                    loading={<>🪦 sending to graveyard...</>}
                                />
                                    {/* {remainingRemints !== remintsLeft && <div className="modify-note">You have {remainingRemints} remints remaining</div>}
                            {!!remainingRemints && !mintedBeforeSnapshot && <div className="mint-info warn">Minted after Graveyard Snapshot</div>} */}</>}
                                {ownerIsYou &&
                                    <TxButton
                                        disabled={loading}
                                        onClick={lockToken}
                                        label={<>⚰️ Lock this token in the Catacombs</>}
                                        loading={<>⚰️ sending to the catacombs...</>}
                                    />
                                }
                            </>}
                            {buried && <>
                                {allowReservations && <>
                                    {isReserved ?
                                        <TxButton
                                            disabled={loading}
                                            status={cancelStatus}
                                            onClick={cancelReservation}
                                            loading={<>🪦 cancelling reservation...</>}
                                            label={<>🪦 reserved by {reserver}</>}
                                        />
                                        :
                                        <TxButton
                                            status={reserveStatus}
                                            disabled={loading}
                                            onClick={reserveToken}
                                            loading={<>🪦 reserving...</>}
                                            label={<>🪦 Reserve for swap</>}
                                        />
                                    }
                                </>}
                            </>}
                            {allowPurchase && !isReserved && <TxButton
                                disabled={loading}
                                onClick={buyToken}
                                loading={<>🪦 purchasing...</>}
                                label={<>🪦 purchase for {etherPrice} Ξ</>}
                            />}
                        </> : <div><ConnectButton /></div>}
                    </div> : <div className="information">
                        <div className="welcome">loading token data...</div>
                        <div className="spinner">
                            <div>
                                <img src="/images/spinny.gif" />
                            </div>
                        </div>
                    </div>}
                </div>

            </div >
            )
}