import {
    useState
} from 'react';

import {
    useAccount,
    useEnsAddress,
} from "wagmi";


import getENV from './util/getENV.js';
import { Link, Navigate, useParams } from 'react-router-dom';
import Header from './Header.js';
import Collection from './Collection.js';
import useSkelephunks from './hooks/useSkelephunks.js';
import defined from './util/defined.js';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import ConnectButton from './ConnectButton.js';
import useObituary from './hooks/useObituary.js';
export default function Wallet() {
    const { override, modules, wagmi: wagmiConfig } = getENV();
    const account = useAccount();
    let isConnected, me;
    if (override) {
        isConnected = true;
        me = override
    } else {
        isConnected = account.isConnected;
        me = account.address;
    }
    const { walletID } = useParams();
    const { obituary } = useObituary();
    const tagAddress = obituary.getAddress(walletID);
    const { status, data: address } = useEnsAddress({ config: wagmiConfig, name: walletID, chainId: 1 });
    const isMe = walletID == 'me' || walletID === me || (me && address === me) || tagAddress == me;
    const isENS = walletID.indexOf('.eth') > -1;
    const isTag = !isENS && !isMe && walletID.indexOf('0x') === -1;
    const wallet = isMe ? me
        : isENS ? address
            : isTag ? tagAddress
                : walletID;
    const { skelephunks } = useSkelephunks();
    const { open } = useWeb3Modal();
    const balance = skelephunks.balanceOf(wallet);
    const collector = isMe ? 'You'
        : isENS ? walletID
            : walletID.substring(0, 13);
    return (<div className="landing" data-w-id="31a62934-7dee-bf92-5793-1bf374967db7">
        <Header subtitle="The Collectoors" />
        <div id="inventory" className="inventory wf-section">
            <div className="token-name free">{collector}</div>
            {isMe && !isConnected ? <ConnectButton />
                : <>
                    {defined(balance) && <h3 className="skeleton-keys">
                        {isMe ? 'Have' : 'Has'} {balance} Skelephunk{balance == 1 ? '' : 's'}
                    </h3>}
                    <Collection wallet={wallet} contract={modules.skelephunks.contract} toggle={true} />
                    <div className="cta-info eth">reveal random new skelephunks</div>
                    <div className="top">
                        <Link onClick={() => { if (!isConnected) open(); }} to={'/mint'} className="cta">MINT YOURS NOW</Link>
                    </div>
                </>}
        </div>
    </div>)
}