import { Link, Navigate, useParams } from "react-router-dom";
import Header from "./Header";
import useSkelephunks from "./hooks/useSkelephunks";
import CLAIM from "./CLAIM";
import getENV from "./util/getENV";
import useSmartContract from "./hooks/useSmartContract";
import { useAccount, useEnsAddress, useEnsName } from "wagmi";
import useMerkle from "./hooks/useMerkle";
// import { Web3Modal, useWeb3Modal } from "@web3modal/wagmi/react";
import equiv from "./util/equiv";
import defined from "./util/defined";
import { useState } from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import ConnectButton from "./ConnectButton";
import useObituary from "./hooks/useObituary";
import { mainnet } from "viem/chains";
import { normalize } from 'viem/ens';
import { useNavigate } from "react-router";
import TxInputButton from "./TxInputButton";

export default function Drop() {
    const { walletID } = useParams();
    const { mainnetChainID, override, modules: { drop: { contract } }, promo: { drop: dropPromo }, wagmi: wagmiConfig } = getENV();
    const { obituary } = useObituary()
    const account = useAccount();
    let isConnected, wallet;
    if (override) {
        isConnected = true;
        wallet = override
    } else {
        isConnected = account.isConnected;
        wallet = account.address;
    }
    const tester = defined(walletID);
    const name = useEnsName(walletID);
    const tag = obituary.getAddress(walletID);
    const { data: ens } = useEnsAddress({ config: wagmiConfig, name: normalize(walletID), chainId: mainnet.id });
    const address = tester ? ens || tag || walletID : wallet;
    const { drops: { proof } } = useMerkle(address);
    const { contract: drop } = useSmartContract(contract);
    const numDrops = drop?.claimableDropsForWallet(address, proof);
    const { skelephunks } = useSkelephunks();
    const numTokens = skelephunks.balanceOf(address);
    const { open } = useWeb3Modal();
    const [promoClicked, setPromoClicked] = useState();
    const clickPromo = () => {
        setPromoClicked(true);
    }
    const ensureConnection = () => {
        if (!isConnected) open();
    }
    const [checking, setChecking] = useState();
    const startChecking = () => { setChecking(true) }
    const handleAddressInput = (e) => { setAddr(e.value) };
    const [addr, setAddr] = useState();
    const validAddress = addr?.match(/^0x[a-fA-F0-9]{40}$/);
    const { data: ensAddress, error: ensError } = useEnsAddress({ tag: validAddress ? 'deadbeef' : addr, chainId: mainnetChainID });

    const toAddress = equiv(validAddress, addr) ? addr : ensAddress;
    const checkWallet = () => {
        Navigate.to(`/drop/${toAddress}`);
    }
    return (<div className="landing" data-w-id="31a62934-7dee-bf92-5793-1bf374967db7">
        <Header subtitle={`${!tester ? 'The ' : ''}Drop${tester ? ' Status' : ''}`} />
        {tester && <Link className="token-name free" to={`/wallet/${address}`}>{walletID.substring(0, 13)}</Link>}
        {isConnected || tester && <h3 className="skeleton-keys">{defined(walletID) ? 'Has' : 'You Have'} {numTokens} Skelephunk{numTokens == 1 ? '' : 's'} </h3>}
        <div id="inventory" className="inventory wf-section">


            <div className="list">


                {tester ? <>
                    {walletID.indexOf('0x') !== 0 && <dev className="mint-info">{address.substr(0, 13)}</dev>}
                    {numDrops > 0 ? <>
                        <div className="id-number">{ens || address.substring(0, 13)}</div>
                        <h1 className="key-name" >HAS {numDrops} DROP{numDrops == 1 ? '' : 'S'}</h1>
                        {!isConnected && <div className="token-name">YOURS?</div>}
                        {(!isConnected || wallet === walletID) &&
                            <div className="top">
                                <Link to={'/drop'} onClick={ensureConnection} className="cta">CLAIM {numDrops == 1 ? 'IT' : 'THEM'} NOW</Link>
                            </div>}
                    </>
                        : <>
                            <h1 className="key-name">NO DROPS</h1>
                            <div className="mint-info error">no {numTokens > 0 ? 'new ' : ''}drop found for this wallet</div>
                            <div className="top">
                                <Link to={'/mint'} onClick={ensureConnection} className="cta">MINT NOW</Link>
                            </div>
                        </>}
                </> : <>
                    {!numDrops && defined(dropPromo) && <div className="top">
                        <a onClick={clickPromo} href={dropPromo.link} target="_blank" className="freedrop"><span className="emoji">💧</span>{dropPromo.button}</a>
                    </div>}
                    {isConnected ? <>
                        <> {!tester && numDrops > 0 && <CLAIM />}</>
                        {numTokens > 0 && <div className="top">
                            <Link to={'/inventory'} className="cta">VIEW YOUR SKELES</Link>
                        </div>}

                        <div className="top">
                            <Link to={'/mint'} className="cta">MINT {numTokens === 0 ? 'YOURS' : 'MORE'} NOW</Link>
                        </div>
                    </> : <>
                        <div style={{ margin: "auto", padding: "1rem" }}><ConnectButton /></div>

                        <TxInputButton
                            label="or check an address for unclaimed drops"
                            placeholder="enter an 0x or ens"
                            value={addr}
                            onInput={handleAddressInput}
                            submitEnabled={defined(toAddress)}
                            onSubmit={checkWallet}
                            submitLabel="check"
                        />
                    </>}
                </>}
            </div>


            {!isConnected && !tester && <div className="mint-info">if you have drops to claim they will appear here</div>}
        </div >
    </div >
    )
}
