import { useEffect, useState } from 'react';
import GAD from './util/GAD';
import useNFTCollection from './hooks/useNFTCollection';
import getENV from './util/getENV';
import useSkelephunks from './hooks/useSkelephunks';
import defined from './util/defined';
const preloads = {};
export default function PFP({ skelephunks, tokenID, busy, gender, setGender, direction, setDirection, controls = true, help = true }) {
    const meta = skelephunks?.getMetadata(tokenID);
    const [genderClicked, setGenderClicked] = useState();
    const [directionClicked, setDirectionClicked] = useState();
    const path = (!defined(meta?.image)) ? '/images/demo/dark' : meta.image?.split('/p')?.[0];
    const id = defined(meta?.image) ? tokenID : 'default';
    const ext = (id === 'default') ? 'gif' : 'png';
    //preload images
    for (let d = 0; d < GAD.directions.length; d++) {
        for (let g = 0; g < GAD.genders.length; g++) {
            const i = new Image();
            i.src = `${path}/${GAD.directions[d]}/${GAD.genders[g]}/${id}.${ext}`;
        }
    }
    function changeGender(gender) {
        setGender(gender);
        setGenderClicked(true);

    }
    function changeDirection(direction) {
        setDirection(direction);
        setDirectionClicked(true);

    }
    function setMale() {
        changeGender('male')
        return false;
    }
    function setFemale() {
        changeGender('female')
        return false;
    }
    function setPhunk() {
        changeDirection('phunk')
        return false;
    }
    function setPunk() {
        changeDirection('punk')
        return false;
    }


    const imgPath = controls ? defined(gender, direction) ? `${path}/${direction}/${gender}/${id}.${ext}` : '/images/preview.gif' : meta?.image;//`${path}/blank.gif`
    return (
        <div className="pfp">
            <div className="preview">
                {id !== 'default' &&
                    <a download={`SKELE #${id} (${direction},${gender})`} className="image-download image-10" href={imgPath} target="_blank">
                        <img className="download-overlay" src="/images/download.svg" />
                    </a>
                }
                <div className="blank">
                </div>
                <img
                    src={imgPath}
                    loading="lazy"
                    alt=""
                    className="image-10"
                />
            </div>
            {controls && help &&
                <div className="prompts">
                    <div className="prompt">
                        {directionClicked ? '' : 'choose a direction'}
                    </div>
                    <div className="prompt">
                        {genderClicked ? '' : 'select a gender'}
                    </div>
                </div>
            }
            {controls &&
                <div className="controls">
                    <button disabled={busy} onClick={setPhunk} className={`button ${direction == 'phunk' ? 'active' : ''} w-button`}>
                        Phunk
                    </button>
                    <button disabled={busy} onClick={setPunk} className={`button ${direction == 'punk' ? 'active' : ''} w-button`}>
                        Punk
                    </button>
                    <button disabled={busy} onClick={setMale} className={`button ${gender == 'male' ? 'active' : ''} w-button`}>
                        Male
                    </button>
                    <button disabled={busy} onClick={setFemale} className={`button ${gender == 'female' ? 'active' : ''} w-button`}>
                        Female
                    </button>
                </div>
            }
        </div>
    )
}