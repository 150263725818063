import { useWeb3Modal, useWeb3ModalState } from '@web3modal/wagmi/react'
import { useAccount } from 'wagmi'
import { disconnect } from '@wagmi/core'
import getENV from './util/getENV';

export default function ConnectButton() {
    const { wagmi: { config } } = getENV();
    const { open } = useWeb3Modal()
    const { isConnected, isConnecting } = useAccount();
    const { open: isOpen, selectedNetworkId } = useWeb3ModalState();

    return (<div className="wc-button">
        <div style={{ margin: '0.5rem 0 0 0' }} className="catacombs action" onClick={() => isConnected ? disconnect(config) : open()}>
            {!isConnected && <img style={{ height: '.8rem', margin: '0 0.5rem 0 0' }} src={`${(isOpen || isConnecting) ? '/images/spin.gif' : 'https://docs.walletconnect.com/img/walletconnect-logo-white.svg#dark-mode-only'}`} />}
            {isConnected ? 'Disconnect' : (isOpen || isConnecting) ? ' Connecting' : <><span className="label short">connect</span><span className="label long">wallet</span></>}
        </div>
        {/* <w3m-connect-button label={isConnected ? 'Disconnect' : 'Connect'} loadingLabel={isConnected ? 'Disconnecting' : 'Connecting'} /> */}
    </div>);

    // 
}