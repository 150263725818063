import {
    useState
} from 'react';

import {
    useAccount,
} from "wagmi";

import getENV from './util/getENV.js';
import UnclaimedKeys from './UnclaimedKeys.js';
import Collection from './Collection.js';
import useSkelephunks from './hooks/useSkelephunks.js';
import scrollToAnchor from './util/scrollToAnchor.js';
import Header from './Header.js';
import { Web3Button } from '@web3modal/wagmi/react';
import useNFTCollection from './hooks/useNFTCollection.js';
import defined from './util/defined.js';
import { Link } from 'react-router-dom';
import useSmartContract from './hooks/useSmartContract.js';
import Headshot from './Headshot.js';
import ConnectButton from './ConnectButton.js';
import PROMO from './PROMO.js';
export default function Inventory() {
    const BATCH_SIZE = 21;
    const { override, collection: { startingIndex }, modules } = getENV();
    const account = useAccount();
    let isConnected, wallet
    if (override) {
        isConnected = true;
        wallet = override
    } else {
        isConnected = account.isConnected;
        wallet = account.address;
    }
    //balances
    //tokens
    const { collection: keys } = useNFTCollection(modules.keys.contract);
    const { collection: catacombs } = useNFTCollection(modules.catacombs.contract);
    const { contract: obituary } = useSmartContract(modules.obituary.contract);

    const { tokenIDs: keysList, balance: keysBalance } = keys.tokensOfOwner(wallet);
    const { tokenIDs: lockedList, balance: lockedBalance } = catacombs.tokensOfOwner(wallet);
    const unclaimedKeys = keys.unclaimedOf(wallet)

    const { skelephunks } = useSkelephunks();
    const { balance: tokenBalance, tokenIDs: tokens } = skelephunks.tokensOfOwner(wallet);
    const claimUntil = unclaimedKeys > 0 ? keys.getClaimDeadline(wallet) : 0;
    const hasTokens = tokenBalance > 0;
    const hasProfile = isConnected && obituary.hasIdentity(wallet);
    const profileTag = obituary.getName(wallet);
    const profileToken = obituary.getToken(wallet);
    const hasLockedTokens = lockedBalance > 0;
    const numKeys = keysList?.length || 0;
    const skeleSize = tokenBalance + lockedBalance;
    const inventorySize = skeleSize + keysBalance;
    const hasInventory = inventorySize > 0;
    const loadedSize = tokens?.length || 0 + lockedList?.length || 0 + keysList?.length || 0;
    const loaded = hasInventory && inventorySize == loadedSize;


    return (<>
        <div className="landing wf-section">
            <Header subtitle="Your Collection" />
            {loaded && <div className="modify-note">come back at any time to update the gender or direction of your Skelephunk{skeleSize > 1 ? 's' : ''}</div>}
            <div id="inventory" className="inventory">
                {isConnected ? <>
                    {unclaimedKeys + numKeys > 0 && <>
                        <div className="div-block-18">
                            <h3 className="skeleton-keys">Skeleton Keys</h3>
                            <div className="keys-info">
                                <div>?</div>
                                <div className="key-info-text">
                                    <div className="key-bubble">
                                        <div className="congratulations">Congratulations!</div>
                                        <div>A skeleton key is rare skelephunks treasure.</div>
                                        {!!keysBalance && <div>Click a key to learn how to use it.</div>}
                                        {!!unclaimedKeys && <>
                                            <div className="claim-note">
                                                You have {unclaimedKeys} unclaimed key{unclaimedKeys > 1 && 's'}.
                                            </div>
                                        </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Collection contract={modules.keys.contract} wallet={wallet} mode={'grid'} toggle={false} />
                        {//unclaimed keys
                            !!unclaimedKeys && <UnclaimedKeys quantity={unclaimedKeys} />
                        }
                        {!!unclaimedKeys && <div className="unclaimed-info">
                            <div className="claim">
                                You have {unclaimedKeys} unclaimed key{unclaimedKeys > 1 && 's'}. Click the <span className="unclaimed">+{unclaimedKeys}</span> to claim.
                            </div>
                            <div className="claim-date">
                                (your key{unclaimedKeys > 1 && 's'} can be claimed until {new Date(claimUntil).toLocaleDateString()})
                            </div>
                        </div>}
                    </>}
                    {defined(lockedBalance) || defined(tokenBalance) ? <>
                        {hasLockedTokens && <>
                            <h3 className="skeleton-keys">
                                Your {lockedBalance > 7 ? lockedBalance : ''} Locked Skelephunk{tokenBalance !== 1 && 's'}
                            </h3>
                            <Collection wallet={wallet} contract={modules.catacombs.contract} toggle={false} />
                        </>}
                        {!hasLockedTokens || hasTokens &&
                            <h3 className="skeleton-keys">
                                {hasTokens ? 'Your' : 'No'} {tokenBalance > 7 ? tokenBalance : ''} Skelephunk{tokenBalance !== 1 && 's'}
                            </h3>
                        }
                        {hasTokens && <Collection wallet={wallet} contract={modules.skelephunks.contract} toggle={true} />}
                        {tokenBalance + lockedBalance == 0 && <div className="top">
                            <Link to={'/mint'} className="cta">MINT YOURS NOW</Link>
                        </div>}
                    </> : <div id="contentSpinner" className="spinner"><img src="/images/spinner.gif" /></div>}

                    {!!(tokenBalance + lockedBalance + numKeys) && <>

                        <div className="modify-note">exclusive access for holders:</div>
                        <PROMO />
                        <div className="top">
                            <div onClick={() => { scrollToAnchor('inventory') }} className="cta">back to top</div>
                        </div></>}
                </> : <><div className="mint-info free">Connect your wallet to view your Skelephunks</div><div><ConnectButton /></div></>}
            </div>
        </div >
    </>)
}