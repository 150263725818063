import { useState } from 'react';
import { useContractReads, useAccount, useContractWrite, usePrepareTransactionRequest, useWaitForTransactionReceipt } from "wagmi";
import { debug, info, log } from "./util/Tracer.js";
import getENV from "./util/getENV.js";
import getRoman from "./util/getRoman.js";
import GAD from './util/GAD.js';
import PFP from './PFP.js';
import useSkelephunks from './hooks/useSkelephunks.js';
import defined from './util/defined.js';
import useNFTCollection from './hooks/useNFTCollection.js';

export default function SKEY({ tokenID, context, closeOverlay }) {
    const { contracts } = getENV();

    const [redeeming, setRedeeming] = useState();

    const [genderToMint, setGenderToMint] = useState('male');
    const [directionToMint, setDirectionToMint] = useState('phunk');
    const genderDirection = GAD.toNum(genderToMint, directionToMint);

    const { skelephunks } = useSkelephunks();
    const { collection: keys } = useNFTCollection(contracts.keys);
    const redeemValue = skelephunks.keyRedemptionAmt();
    const meta = keys.getMetadata(tokenID);

    const traitsJSX = meta?.attributes?.map((t) => {
        return (
            <div className="trait" key={t.trait_type}>
                <div className="trait-type">{t.trait_type}:</div>
                <div className="value">{t.value}</div>
            </div>
        )
    });

    const { write: redeemKey, status: redeemStatus, error: redeemError } = skelephunks.redeemKeyForSkelephunks(tokenID, genderDirection).config({
        gasLimit: 500000,
        onSuccess: closeOverlay
    })

    const enterRedeeming = () => {
        setRedeeming(true);
    }
    const mintPending = redeemStatus == 'loading';

    return (
        <div id={`token${tokenID}`} className="nft">
            <h1 className="key-name">
                {meta?.name}
            </h1>
            {defined(meta) ? <>
                <div className="token">
                    <div className="jpeg">
                        {redeeming ?
                            !mintPending ? <PFP onDirectionSet={setDirectionToMint} onGenderSet={setGenderToMint} />
                                : <div id="pfpSpinner" className="spinner"><img src="/images/spinner.gif" /></div>
                            : <div className='preview'>
                                <img width="100%" src={meta.image || "/images/SKELEKEY.gif"}></img>
                            </div>}
                        {/* //tokenInfo?.metadata?.image}></img>} */}
                        <button
                            disabled={redeemStatus == 'loading' || genderToMint == null || directionToMint == null}
                            className="cta w-button update-token"
                            onClick={redeeming ? redeemKey : enterRedeeming}
                        >
                            {(redeemStatus == 'error') ? 'TRY AGAIN'
                                : (redeemStatus == 'loading') ? 'REDEEMING...'
                                    : (redeemStatus == 'signing') ? 'SIGN TX..'
                                        : redeeming ? 'SURRENDER KEY?'
                                            : 'REDEEM KEY'}
                        </button>
                        <div className="mint-info">
                            {redeeming ?
                                <>
                                    <div>select a gender and direction to redeem</div>
                                    <div className="error">this action is completely irreversible</div></>
                                :
                                <div className="free">redeem this key for {redeemValue} Skelephunks</div>
                            }
                        </div>
                    </div>
                    <div className="metadata">
                        <div>{meta.description || `A rare, valuable piece of Skelephunks treasure. Redeemable immediately for ${redeemValue} mints from the Skelephunks Crypt, while supplies last. Possibly unlocks something even better in the future? The choice is yours. There will only ever be 13 Skelephunk Keys in existence.`}</div>
                        <div className="traits">
                            {traitsJSX}
                        </div>
                    </div>
                </div>
            </> : <img src="/images/spinner.gif" />}
        </div>
    )
}