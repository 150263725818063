import GAD from "../util/GAD";
import defined from "../util/defined";
import getENV from "../util/getENV";
import useNFTCollection from "./useNFTCollection";

export default

    function useSkelephunks(contractOverride) {
    const { modules: { skelephunks: { contract } } } = getENV();
    const nftCollection = useNFTCollection(contractOverride || contract);
    const skelephunks = nftCollection.collection;
    const getMetadata = skelephunks.getMetadata;
    skelephunks.getMetadata = (id) => {
        const meta = getMetadata(id);
        if (defined(meta)) skelephunks.getTokenInfo(id) // preload tokenInfo
        return meta
    }
    skelephunks.getTokenInfo = (id) => {
        const gad = skelephunks.getGenderAndDirection(id);
        return {
            id,
            minter: skelephunks.minterOf(id),
            owner: skelephunks.ownerOf(id),
            timestamp: skelephunks.mintedAt(id),
            genderAndDirection: gad,
            gender: GAD.genderOf(gad),
            direction: GAD.directionOf(gad)
        }
    }
    return { ...nftCollection, skelephunks, ext: nftCollection }
}