import { useState } from "react";
import defined from "../util/defined";
import useSmartContract from "./useSmartContract";
import equiv from "../util/equiv";
import useRedraw from "./useRedraw";
const metadata = [];
const metaq = [];
let refresh;
export default function useNFTCollection(contract) {
    const smartContract = useSmartContract(contract);
    const { contract: collection } = smartContract;
    const { redraw } = useRedraw();
    collection.getMetadata = (id) => {
        const uri = collection.tokenURI(id);
        let meta = {};
        if (defined(uri)) {
            meta = metadata[uri];
            if (!defined(meta)) {
                meta = metadata[uri] = {};
                if (metaq.indexOf(uri) === -1) {
                    metaq.push(uri)
                    let error;
                    fetch(uri).then((data) => {
                        data.json().then((json) => {
                            json.traits = {}
                            json.attributes.forEach(att => json.traits[att.trait_type] = att.value);
                            metadata[uri] = json;
                            refresh = true;
                        }).catch((e) => {
                            error = e;
                        }).finally(() => {
                            metaq.splice(metaq.indexOf(uri), 1);
                            if (metaq.length === 0 && refresh) {
                                refresh = false;
                                redraw();
                            }
                        })
                    });
                }
            }
        }
        return meta
    }
    collection.tokensOfOwner = (wallet, loadAmt) => {
        let balance, tokenIDs;
        if (defined(wallet)) {
            balance = collection.balanceOf(wallet);
            const max = defined(loadAmt) ? Math.min(loadAmt, balance) : balance;
            for (let i = 0; i < max; i++) {
                tokenIDs = tokenIDs || [];
                const id = collection.tokenOfOwnerByIndex(wallet, i);
                if (defined(id)) {
                    tokenIDs.push(id);
                }
            }
            tokenIDs = tokenIDs?.length === max ? tokenIDs : undefined
        }
        return { wallet, balance, tokenIDs }
    }

    return { ...smartContract, collection }
}