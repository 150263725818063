import { useContractWrite, usePrepareTransactionRequest, useWaitForTransactionReceipt } from "wagmi";
import getENV from "./util/getENV.js";

export default function UnclaimedKeys({ quantity }) {
    const { modules: { keys: { contract } } } = getENV();


    const {
        config,
        status: prepStatus,
        error: prepError
    } = usePrepareTransactionRequest({
        ...contract,
        functionName: 'claimKeys',
        overrides: {
            gasLimit: 1000000
        }
    })
    const {
        write: claimKeys,
        status: writeStatus,
        error: writeError,
        data: writeData
    } = useContractWrite(config);
    const {
        status: Status,
        data: txData,
        isLoading: claiming
    } = useWaitForTransactionReceipt({
        hash: writeData?.hash,
    })
    const prerevealURI = '/images/SKELEKEY.gif'
    const spinnerURI = '/images/spinny.gif'
    if (quantity && prerevealURI) {
        return (
            <div onClick={claimKeys} className="thumbnail">
                <img
                    src={claiming ? spinnerURI : prerevealURI}
                    loading="lazy"
                    alt=""
                    className={'key-image'}
                />
                <h1 className="key-id unclaimed">{claiming ? `…` : `+${quantity}`}</h1>
            </div>
        )
    }
}