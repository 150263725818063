import { Link, useParams } from "react-router-dom";
import Header from "./Header";
import useSkelephunks from "./hooks/useSkelephunks";
import CLAIM from "./CLAIM";
import getENV from "./util/getENV";
import useSmartContract from "./hooks/useSmartContract";
import { useAccount, useEnsAddress, useEnsName } from "wagmi";
import useMerkle from "./hooks/useMerkle";
// import { Web3Modal, useWeb3Modal } from "@web3modal/wagmi/react";
import equiv from "./util/equiv";
import defined from "./util/defined";
import { useState } from "react";

export default function BRB() {

    const { modules, opensea, etherscan } = getENV();
    return (<div className="landing" data-w-id="31a62934-7dee-bf92-5793-1bf374967db7">

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1 style={{ margin: '1rem' }} className="key-name">
                ERROR 420: DOWN BAD
            </h1>
            <div style={{ maxWidth: '24rem' }}>
                <img style={{ maxWidth: '20rem', margin: '1rem' }} src="images/demo/dark/blank.gif" />
                <h3 className="skeleton-keys">This app is experiencing some technical difficulties</h3>
                <div className="mint-info">All of your skelephunks assets are safe on-chan. Until app functionality is restored, you can use <a className="error" href={`${etherscan.address(modules.skelephunks.contract.address)}#code`}>Etherscan</a> or <a className="error" href={`${opensea.collection(modules.skelephunks.contract.address)}`}>OpenSea</a> to interact with your tokens in a pinch.</div>
                <div className="mint-info">Follow us on <a className="error" href={`https://twitter.com/skelephunks`}>Twitter</a> for updates.</div>
                <div className="iconnav">
                    <a href="https://discord.gg/YS7FgPudTr" target="_blank">
                        <img src="images/28-283551_discord-icon-png.png" loading="lazy" alt="" className="social" />
                    </a>
                    <a href="https://opensea.io/collection/skelephunks-official" target="_blank">
                        <img src="images/OS.png" loading="lazy" alt="" className="social" />
                    </a>
                    <a href="https://twitter.com/skelephunks" target="_blank">
                        <img src="images/2021-Twitter-logo---white.png" loading="lazy" alt="" className="social" data-ix="new-interaction" />
                    </a>
                </div>
            </div>
        </div>
    </div >
    )
}
