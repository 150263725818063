import { isString, isNumber } from 'util';

// _gdToPath[0] = "phunk/male";00 
// _gdToPath[1] = "phunk/female";01
// _gdToPath[2] = "punk/male";10
// _gdToPath[3] = "punk/female";11

//  uint256 toSet = gender*2 + direction; XXX THIS IS NOT THE CORRECT FORMULA ABOVE 
// direction * 2 + gender does it,
const directions = ['phunk', 'punk'];
const genders = ['male', 'female'];
const inRange = num => 0 <= num && num <= 3;
const inDirections = term => directions.indexOf(term) + 1;
const inGenders = term => genders.indexOf(term) + 1;
const inTerms = (gender, direction) => { return inDirections(direction) && inGenders(gender); }
const directionOf = num => inRange(num) ? directions[Math.floor(num / 2)] : undefined;
const genderOf = num => inRange(num) ? genders[Math.floor(num % 2)] : undefined;
const obj = num => { return { gender: genderOf(num), direction: directionOf(num) } };

const genderNum = gender => inGenders(gender) ? genders.indexOf(gender) : 0;
const directionNum = direction => inDirections(direction) ? directions.indexOf(direction) : 0;
const toNum = (gender, direction) => { return inTerms(gender, direction) ? directionNum(direction) * 2 + genderNum(gender) : undefined };

const num = ({ gender, direction }) => { return inTerms(gender, direction) ? toNum(gender, direction) : undefined };
const toNumObj = (gender, direction) => { return { gender: genderNum(gender), direction: directionNum(direction) } };
const numObj = ({ gender, direction }) => toNumObj(gender, direction);
const toArgs = (gender, direction) => [genderNum(gender), directionNum(direction)];
const args = ({ gender, direction }) => toArgs(gender, direction);


const sameObj = (o) => obj(num(o));
const sameNum = (n) => num(obj(n));
export default {
    genders,
    directions,
    genderOf,
    directionOf,
    obj,
    genderNum,
    directionNum,
    num,
    toNum,
    args,
    toArgs,
    sameNum,
    sameObj
}