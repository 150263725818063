import PFP from './PFP.js';
import { useEffect, useState } from 'react';
import { useEnsName, useAccount, useContractWrite, usePrepareTransactionRequest, useWaitForTransactionReceipt, useWriteContract } from "wagmi";
import getENV from "./util/getENV.js";
import GAD from "./util/GAD.js";
import { Link, useLocation } from "react-router-dom";
import defined from './util/defined.js';
import equiv from "./util/equiv.js";
import { Web3Button } from '@web3modal/wagmi/react';
import useObituary from './hooks/useCatacombs.js';
import useCatacombs from './hooks/useCatacombs.js';
import useSmartContract from './hooks/useSmartContract.js';
import ConnectButton from './ConnectButton.js';

export default function MYSKELE({ tokenID, closeFunction }) {
    const { override, modules, wagmi: { config: wagmiConfig } } = getENV();
    const account = useAccount();
    let isConnected, wallet
    if (override) {
        isConnected = true;
        wallet = override
    } else {
        isConnected = account.isConnected;
        wallet = account.address;
    }
    const { clear: clearSkelephunks, catacombs } = useCatacombs();
    const { contract: obituary } = useSmartContract(modules.obituary.contract);
    const hasProfile = obituary.hasIdentity(wallet);
    const profileToken = obituary.getToken(wallet);
    const isPFP = profileToken == tokenID;
    const profileName = obituary.getName(wallet);
    const meta = catacombs.getMetadata(tokenID);
    const info = catacombs.getTokenInfo(tokenID);
    const [gender, setGender] = useState();
    const [direction, setDirection] = useState();
    useEffect(() => {
        if (defined(meta?.traits)) {
            if (!defined(gender)) {
                setGender(meta.traits.Gender.toLowerCase());
            }
            if (!defined(direction)) {
                setDirection(meta.traits.Direction.toLowerCase());
            }
        }
    })

    const { data: minterName } = useEnsName({ config: wagmiConfig, address: info.minter });
    const { data: ownerName } = useEnsName({ config: wagmiConfig, address: info.owner });
    const mintedAt = defined(info.timestamp) ? new Date(info.timestamp * 1000).toLocaleDateString() : 'onto the chain';
    const lock = catacombs.lockedAt(tokenID)
    const lockedAt = defined(lock) ? new Date(lock * 1000).toLocaleDateString() : '';
    const minterIsOwner = equiv(info.minter, info?.owner);
    const minterIsYou = equiv(info.minter, wallet);
    const ownerIsYou = equiv(info.owner, wallet);
    const shortMinter = `Anon  (${info.minter?.substring(0, 13)})`;
    const shortOwner = `Anon  (${info.owner?.substring(0, 13)})`;
    const changed = defined(gender, direction, info) && (!equiv(gender, info.gender) || !equiv(direction, info.direction));
    const cleanup = () => {
        closeFunction();
        clearSkelephunks();
    }

    const traitsJSX = meta.attributes?.map((t) => {
        return (
            <div className="trait" key={t.trait_type}>
                <div className="trait-type">{t.trait_type}:</div>
                <div className="value">{t.value}</div>
            </div>
        )
    });
    // CHANGE GAD


    const { status: gadStatus, hash: gadHash, write: setGenderAndDirection } = catacombs.setGenderAndDirection(tokenID, ...GAD.toArgs(gender, direction))
    const updateGAD = () => {
        setGenderAndDirection?.();
    };


    //UNLOCK TOKEN

    const { status: unlockStatus, error: unlockError, write: unlockToken } = catacombs.unlockMyToken(tokenID);
    const unlockEnabled = ownerIsYou && !hasProfile && defined(wallet, modules.obituary.contract.address, tokenID);


    const [tag, setTag] = useState('');
    const [stitching, setStitching] = useState();
    const stitchName = tag || profileName;
    //STITCH IDENTITY 
    const stitchEnabled = ownerIsYou && !hasProfile && defined(wallet, modules.obituary.contract.address, stitchName, tokenID)

    const { status: stitchStatus, error: stitchError, write: stitchIdentity } = obituary.setMyProfile(stitchName, tokenID, [], [], [], []).config({ enabled: stitchEnabled });


    const handleNameInput = (e) => {
        setTag(e.target.value?.toLowerCase().replace(' ', '-').replace(/^[0-9-]/, '').replace(/[^a-z0-9-]/, '').replace(/-{2,}/, '-'));
        console.log(tag);
    }
    const startStitching = () => {
        setStitching(true)
    }
    const submitStitch = () => {
        stitchIdentity();
        setStitching(false);
    }

    //UPDATE PFP 

    const { status: pfpStatus, hash: pfpHash, write: pfpToken } = obituary.setMyToken(tokenID);
    const pfpEnabled = ownerIsYou && hasProfile && defined(wallet, modules.obituary.contract.address, tokenID);




    const loading = -1 < [gadStatus, unlockStatus, stitchStatus, pfpStatus].indexOf('loading');

    const signing = -1 < [gadStatus, unlockStatus, stitchStatus, pfpStatus].indexOf('signing');

    const nameIsAvailable = tag?.length > 0 && obituary.nameAvailable(tag);
    return (
        <div id={`token${tokenID}`} className="nft">
            <div>{defined(tokenID) && <h1 className={`token-name sealed-at`}>
                LOCKED SKELE #{tokenID}
            </h1>}
                {isPFP && <div className="promo-copy">
                    <div style={{ color: 'rgb(3, 255, 255)', textAlign: 'center' }}>Your Profile Picture 👤</div>
                </div>}</div>
            <div className="token">
                <div className="jpeg">
                    {defined(meta?.image, gender, direction) ? <>
                        <PFP
                            busy={equiv(gadStatus, 'loading') || equiv(unlockStatus, 'loading')}
                            direction={direction}
                            setDirection={setDirection}
                            skelephunks={catacombs}
                            tokenID={tokenID}
                            gender={gender}
                            setGender={setGender}
                        />
                        {ownerIsYou && changed ?
                            <button
                                disabled={equiv(gadStatus, 'loading') || equiv(unlockStatus, 'loading')}
                                className="cta w-button update-token"
                                onClick={updateGAD}
                            >
                                {equiv(gadStatus, 'error') ? 'TRY AGAIN'
                                    : equiv(gadStatus, 'loading') ? <>UPDATING...</>
                                        : 'UPDATE TOKEN'}
                            </button>
                            : <div className="modify-note">click above to see pfp variants</div>}
                    </> : <div id="pfpSpinner" className="spinner"><img src="/images/spinner.gif" /></div>}
                    {ownerIsYou &&
                        <div className="metadata send-widget">
                            {tokenID !== profileToken && <>

                                {!hasProfile ? <>
                                    {!stitching ?
                                        <button
                                            disabled={loading}
                                            className="catacombs action w-button"
                                            onClick={startStitching}>
                                            {equiv(stitchStatus, 'signing') ? 'sign the transaction' : equiv(stitchStatus, 'loading') ? '👤 creating your profile..' : '👤 Create Profile with this PFP'}
                                        </button>
                                        : <div className="input">
                                            <input type="text" style={{ width: '0' }} value={stitchName} onInput={handleNameInput} className="address-input" placeholder="Choose a Name"></input>
                                            {(stitchName === profileName || nameIsAvailable) && <button className="send-button w-button" disabled={!stitchEnabled} onClick={submitStitch} >stitch</button>}
                                        </div>
                                    }
                                </> : <button
                                    disabled={loading}
                                    className="catacombs action w-button"
                                    onClick={pfpToken}>
                                    {equiv(pfpStatus, 'signing') ? 'sign the transaction' : equiv(pfpStatus, 'loading') ? '👤 updating your profile..' : '👤 make this your pfp'}
                                </button>}
                            </>}
                        </div>}
                </div>
                {defined(traitsJSX) || defined(info.timestamp) ? <div className="information">
                    <div className="metadata">
                        <div className="traits">
                            {defined(traitsJSX) ? <>{traitsJSX}</>
                                : <div className="welcome">
                                    <div>loading token metadata...</div>
                                    <div className="spinner">
                                        <div>
                                            <img src="/images/spin.gif" />
                                        </div>
                                    </div>
                                </div>}
                        </div>
                        <div className="token-info"> {defined(info.timestamp) ? <>
                            <div className="minted-on">
                                <div>Minted {mintedAt}</div>
                            </div>
                            <div className="minted-by">
                                <div> by {
                                    minterIsYou ? <span className={`${minterIsOwner ? 'identity' : ''}`}>you</span>
                                        : <Link to={`/wallet/${minterName ? minterName : info.minter}`} className="free">{minterName ? minterName
                                            : shortMinter}</Link>
                                }</div>
                            </div>
                            {defined(lockedAt) &&
                                <div className="sealed-at catacombs">
                                    <div>Locked since {lockedAt}</div>
                                </div>
                            }

                            {!minterIsOwner &&
                                <div className="minted-by">
                                    <div>owned by {
                                        ownerIsYou ? <span className="identity">you</span>
                                            : <span className="paid">{ownerName ? ownerName
                                                : shortOwner}</span>
                                    }</div>
                                </div>
                            }

                        </>
                            : <div className="welcome">
                                <div>loading token info...</div>
                                <div className="spinner">
                                    <div>
                                        <img src="/images/spinny.gif" />
                                    </div>
                                </div>
                            </div>}
                        </div >

                    </div>
                    {!isConnected ?
                        <div><ConnectButton /></div> : ownerIsYou && tokenID !== profileToken &&
                        <button
                            style={{ marginBottom: '.5rem' }}
                            disabled={loading}
                            className="catacombs metadata action w-button"
                            onClick={unlockToken} >
                            {equiv(unlockStatus, 'signing') ? '✍️ please sign the transaction..'
                                : equiv(unlockStatus, 'loading') ? <>⚰️ unlocking from Catacombs... </>
                                    : <>⚰️ Unlock this token</>}
                        </button>}
                </div> : <div className="metadata">
                    <div className="welcome">loading token data...</div>
                    <div className="spinner">
                        <div>
                            <img src="/images/spinny.gif" />
                        </div>
                    </div>
                </div>}

            </div>

        </div>)
}