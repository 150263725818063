import { useState } from "react";
import { getMerkleProofFromFile, getMerkleRoot } from "../util/MerkleGenerator"
import getENV from "../util/getENV";
import equiv from "../util/equiv";
import defined from "../util/defined";

const mintProofs = {}
const mintRoots = [];
const dropProofs = {};
let dropRoot;

let rooted;
export default function useMerkle(wallet) {
    const { merkle } = getENV();
    const [, setLastLoaded] = useState();
    if (!rooted) {// do once regardless of wallet
        getMerkleRoot(merkle.phunks).then((r1) => {
            mintRoots[0] = r1;
            getMerkleRoot(merkle.allow).then((r2) => {
                mintRoots[1] = r2;
                setLastLoaded(r2);
            })
        });
        getMerkleRoot(merkle.drops).then(root => {
            dropRoot = root;
            setLastLoaded(root);
        })
        rooted = true;
    }
    if (defined(wallet) && !defined(mintProofs[wallet], dropProofs[wallet])) {//do once per wallet
        mintProofs[wallet] = [[], []];
        getMerkleProofFromFile(wallet, merkle.phunks).then(m1 => {
            mintProofs[wallet][0] = m1;
            getMerkleProofFromFile(wallet, merkle.allow).then(m2 => {
                mintProofs[wallet][1] = m2;
                setLastLoaded(m2);
            });
        });
        getMerkleProofFromFile(wallet, merkle.drops).then(proof => {
            dropProofs[wallet] = proof;
            setLastLoaded(proof);
        });
    }
    return { mint: { roots: mintRoots, proofs: mintProofs[wallet] || [[],[]] }, drops: { root: dropRoot, proof: dropProofs[wallet] || [] } }
}