import { useState } from "react";
import { useNavigate } from "react-router-dom";
import getENV from "./util/getENV";
import equiv from "./util/equiv";
import useSkelephunks from "./hooks/useSkelephunks";
import defined from "./util/defined";

export default function SearchBox({ hidden }) {
    const navigate = useNavigate();
    const { skelephunks } = useSkelephunks();

    const [searchID, setSearchID] = useState('');
    const [searching, setSearching] = useState();
    const owner = searchID.length > 0 ? skelephunks.ownerOf(Number(searchID)) : 0;

    const openSearch = () => {
        setSearching(true);

    }
    const handleSearchInput = e => {
        setSearchID(e.target.value.match(/^[0-9]+$/) ? e.target.value.substring(0, 4) : '');
    }
    const handleSearch = () => {
        navigate(`/token/${Number(searchID)}`);
        if (hidden) setSearching(false);
        setSearchID('')
    }
    const handleKeyUp = (e) => {
        if (equiv(e.key, 'Enter') && owner) {
            handleSearch();
        }
    }
    return (
        <div className="search-widget">
            {hidden && !searching ? <div
                onClick={openSearch}
                className="search-link"
            >view any token</div> :
                <div className={`search ${owner ? 'active' : ''}`}>
                    <div>
                        <input
                            type="text"
                            value={searchID}
                            className="search-input"
                            onChange={handleSearchInput}
                            onKeyUp={handleKeyUp}
                            placeholder="enter a token ID"
                            autoFocus={true}
                        >
                        </input>
                    </div>
                    <button
                        onClick={handleSearch}
                        className={`search-button w-button ${!owner ? 'disabled' : 'active'}`}
                        disabled={!defined(owner)}
                    >view</button>
                </div>
            }
        </div>
    )
}