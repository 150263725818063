//
// Author: Autopsyop for Neuromantic Industries
//
import {
    LogLevel,
    setLogLevel,
    info
} from './util/Tracer';

import {
    ethers
} from 'ethers';


import {
    useState
} from 'react';

import {
    useAccount,
    usePrepareTransactionRequest,
    useSendTransaction,
    useWaitForTransactionReceipt,
    useFeeData,
    useWriteContract
} from "wagmi";

//COMPONENTS
import PFP from './PFP';
import getENV from './util/getENV';
import GAD from './util/GAD';
import useMerkle from './hooks/useMerkle';
import Header from './Header';
import useSmartContract from './hooks/useSmartContract';
import defined from './util/defined';
import { Link, useNavigate } from 'react-router-dom';
import useSkelephunks from './hooks/useSkelephunks';
import equiv from "./util/equiv.js";
import ConnectButton from './ConnectButton.js';

/**************************************** EDITABLE *****************************************/
setLogLevel(LogLevel.DEBUG);

/*******************************************************************************************/
export default function Mint() {
    const mintPhases = ['pre', 'claim', 'list', 'pub', 'zoo', 'post']
    const { override, opensea, etherscan, modules } = getENV();
    //MODEL
    //account
    const account = useAccount();
    let isConnected, wallet
    if (override) {
        isConnected = true;
        wallet = override
    } else {
        isConnected = account.isConnected;
        wallet = account.address;
    }
    const gasPrice = useFeeData({ address: wallet }).data?.formatted?.gasPrice;
    const { mint: { proofs: merkleProofs } } = useMerkle(wallet);
    /********************************STATE***********************************/
    const [numToMint, setNumToMint] = useState(13);
    const [genderToMint, setGenderToMint] = useState(GAD.genders[0]); //Math.floor(Math.random() * GAD.genders.length)]); 
    const [directionToMint, setDirectionToMint] = useState(GAD.directions[0]); //Math.floor(Math.random() * GAD.directions.length)]); 
    const genderDirection = GAD.toNum(genderToMint, directionToMint);
    /********************************CHAIN***********************************/
    const { ext, status: dataStatus, skelephunks } = useSkelephunks();
    const { contract: graveyard } = useSmartContract(modules.graveyard.contract);

    const maxPossibleSupply = skelephunks.maxPossibleSupply();
    const tokenSupply = skelephunks.totalSupply();
    const maxMintableSupply = skelephunks.maxMintableSupply();
    const numMintedRegular = skelephunks.numMintedRegular();
    const maxReserveSupply = skelephunks.maxReserveSupply();
    const numMintedReserve = skelephunks.numMintedReserve();
    const phunkClaimsRemain = skelephunks.phunkClaimsRemain();
    const totalClaimsMade = skelephunks.totalClaimsMade(wallet);
    const mintPrice = skelephunks.mintPrice();
    const freeMint = mintPrice === 0;
    //GRAVEYARD
    //remints
    const numFreeMinted = totalClaimsMade;
    /***************************************CONST*************************************/

    const maxMintsForWallet = skelephunks.maxMintsPerWallet(wallet, ...merkleProofs);

    const mintStatus = skelephunks.mintStatus();
    const mintPhase = mintPhases[mintStatus];

    const numMinted = skelephunks.numMinted(wallet);
    const maxMinted = mintStatus < 4 && numMinted > maxMintsForWallet - 1;
    const walletCanMint = skelephunks.walletCanMint(wallet, ...merkleProofs);
    const numFreeMints = skelephunks.getNumFree(wallet, numToMint, ...merkleProofs);
    const { balance: tokenBalance } = skelephunks.tokensOfOwner(wallet)

    //paid
    const numPaid = numMinted - totalClaimsMade;
    //maxmint
    const maxMinting = equiv(numToMint, maxMintsForWallet);
    const claiming = numToMint <= numFreeMints;
    //pricing
    //access
    const initializing = equiv(dataStatus, 'initializing');
    const mintNotOpen = Boolean([0, 5, 6].indexOf(mintStatus) + 1);//mint not started, mint ended, supply gone
    //minting
    const cartPrice = defined(mintPrice) ? `${ethers.utils.formatEther(mintPrice.toString())} Ξ` : '--';
    const mintQty = freeMint ? 13 : Math.max(numToMint, 1);
    const mintCost = skelephunks.getMintCost(wallet, mintQty, ...merkleProofs);
    const calcPay = defined(mintPrice) ? mintQty * mintPrice : mintPrice
    const amtToPay = defined(mintCost) ? mintCost : calcPay;
    const cartTotal = defined(amtToPay) ? `${ethers.utils.formatEther(amtToPay.toString())} Ξ` : '--';
    const { status: callStatus, error: callError, write: mint } = skelephunks.mint(mintQty, genderDirection, ...merkleProofs).config({
        value: amtToPay?.toString(),
        gas: 300000 + 100000 * mintQty
    });

    const readyToMint = defined(mint) && !!directionToMint && !!genderToMint;
    const mintPending = equiv(callStatus, 'loading');// && equiv(requestStatus, 'success');
    const signPending = equiv(callStatus, 'signing');
    const mintError = equiv(callStatus, 'error');// || equiv(Status, 'error');
    const mintSuccess = equiv(callStatus, 'success');
    const mintErrorMsg = callError?.message.split('.')[0];


    const scrollToAnchor = (anchor) => {
        anchor = equiv(anchor.indexOf('#'), 0) ? anchor : `#${anchor}`;
        window.location.href = anchor;
    }
    //controller 
    function callMint() {
        scrollToAnchor('home');
        mint();
    }
    function onQuantityChange(e) {
        const val = e.target.value;
        const value = equiv(val, 'max') ? maxMintsForWallet : val;
        setNumToMint(value);
        scrollToAnchor('home');
    }
    const reloadPage = () => {
        window.location.href = document.href;
    }

    // VIEW

    // menu
    const selectedQuantityOption = maxMinting ? 'max' : numToMint;//for updating the drop-down
    const quantityOptionsJSX = [];
    for (let i = 1; i < maxMintsForWallet; i++) {
        if (
            i <= 13
            || i <= 100 && equiv(i % 10, 0)
            || i <= 1000 && equiv(i % 100, 0)
            || i <= 10000 && equiv(i % 1000, 0)
        ) quantityOptionsJSX.push(<option key={i} value={i}>{i}</option>);
    }
    quantityOptionsJSX.push(<option key='max' value="max">MAX</option>);

    //progress
    let pronoun = equiv(numFreeMinted, numMinted) ? equiv(numFreeMinted, 1) ? 'it' : 'they' : numFreeMinted;
    let verb = equiv(numFreeMinted, 1) ? 'was' : 'were';
    let phrase = `${pronoun} ${verb} free`
    const freePhrase = numFreeMinted > 0 ? phrase : '';
    pronoun = equiv(numFreeMinted, 0) ? equiv(numPaid, 1) ? 'it' : 'they' : numPaid;
    verb = equiv(numPaid, 1) ? 'was' : 'were';
    phrase = `${pronoun} ${verb} paid`;
    const paidPhrase = numPaid > 0 && numFreeMinted > 0 ? phrase : '';
    //info
    const mintStateReason = [`mint hasn't opened yet`, `public mint hasn't opened yet`, ``, ``, `mintable supply has minted out`, `the collection is minted out`][mintStatus];
    return (
        <div id="mint" className={`landing wf-section`}>
            <Header subtitle="The Mint" />
            <div className="content">

                <div className="mint-content left">
                    <div className="on-chain blurb">
                        <div className="blurb-header">
                            on-chain info
                        </div>
                        {defined(tokenSupply, maxPossibleSupply) && maxPossibleSupply > 0 && <div className="info">
                            {numMintedRegular}/{maxMintableSupply} minted
                        </div>}
                        {(defined(numPaid, numFreeMinted)) && <>
                            <div className="paid">
                                {numPaid !== 0 ? `you minted ${numPaid}` : ''}
                            </div>
                            <div className="info free">
                                {numFreeMinted !== 0 && `you claimed ${numFreeMinted}`}
                            </div></>}
                        {defined(numMintedReserve, maxReserveSupply) && maxReserveSupply > 0 && <div style={{ color: '#bfbfbf' }} >
                            {numMintedReserve}/{maxReserveSupply} claimed
                        </div>}
                    </div>
                </div>

                <div className="mint-inputs">
                    {maxMinted ? '' : <>

                        {!mintPending ? <PFP
                            gender={genderToMint}
                            direction={directionToMint}
                            setDirection={setDirectionToMint}
                            help={true}
                            setGender={setGenderToMint} />
                            : <div id="pfpSpinner" className="spinner"><img src="/images/spinner.gif" /></div>}
                    </>}
                    <div className={`mint-info ${mintError ? "error" : ''}`}>
                        {initializing ? 'loading data from ethereum...'
                            : mintNotOpen ? mintStateReason
                                : !isConnected ? <>connect your wallet to mint</>
                                    : equiv(dataStatus, 'error') ? <>error loading skelephunks data <span className="info-link" onClick={reloadPage}>try again</span></>
                                        : maxMinted ? <span className="free">this wallet is max minted</span>
                                            : equiv(mintPhase, 'claim') && !phunkClaimsRemain ? <span className="free">phunk free mint supply has been fully claimed</span>
                                                : equiv(walletCanMint, false) && numMinted > 0 ? 'this wallet can’t mint any more yet'
                                                    : equiv(walletCanMint, false) ? 'this wallet cannot mint yet'
                                                        : signPending ? <span className="attn">✍️ please sign the transaction</span>
                                                            : mintPending ? <>minting your skelephunk{numToMint == 1 ? '' : 's'} <br /><a href={etherscan.tx()} className="info-link" to="/inventory" >view transaction</a></>
                                                                : mintError ? <>{mintErrorMsg}<br /><span className="info-link" onClick={mint}>try again</span></>
                                                                    : mintSuccess ? <>congrats on your mint!<br /><Link className="info-link" to="/inventory" >view tokens</Link></>
                                                                        : equiv(mintPhase, 'claim') ? <span className="free">welcome, phunk. claim your free mint</span>
                                                                            : equiv(mintPhase, 'list') ? <span className="free">you are on the allowlist</span>
                                                                                : readyToMint ? <span className="free">choose a quantity and click mint</span>
                                                                                    : equiv(mintPhase, 'zoo') ? <span className="free">select a gender and direction to mint</span>
                                                                                        : numMinted > 0 ? 'how many more do you wanna mint?'
                                                                                            : 'how many do you wanna mint?'
                        }
                    </div>

                    {(!isConnected || !walletCanMint && !maxMinted) && defined(tokenSupply, maxPossibleSupply) &&
                        <a href={opensea.skelephunks} target="_blank" className="fomo ext-block-3">{tokenSupply}/{maxPossibleSupply} Revealed</a>
                    }
                    {isConnected ? <>
                        {!maxMinted &&
                            <div className="form-block w-form">
                                {(walletCanMint && readyToMint) ?
                                    <div className="form"
                                        id="wf-form-Mint-Form"
                                        name="wf-form-Mint-Form">
                                        {maxMintsForWallet > 1 &&
                                            <select
                                                disabled={initializing || mintPending || !readyToMint || signPending}
                                                id="Quantity"
                                                name="Quantity"
                                                data-name="Quantity"
                                                required=""
                                                className="select-field w-select"
                                                onChange={onQuantityChange}
                                                value={selectedQuantityOption}
                                            >
                                                {quantityOptionsJSX}
                                            </select>}
                                        <button
                                            onClick={callMint}
                                            className="cta"
                                            disabled={initializing || mintPending || !readyToMint || signPending}>
                                            {mintPending ? 'HODL' : signPending ? 'SIGN' /*<img style={{ marginTop: '-.5rem' }} src="/images/spin.gif" />*/ : claiming ? `CLAIM` : `MINT`}
                                        </button>
                                    </div>
                                    : walletCanMint ? <img style={{ height: '6rem' }} src="/images/spinner.gif" /> : ''
                                }</div>
                        }
                    </> : <ConnectButton />}
                    <div className="mint-info tos-text">
                        {tokenBalance > 0 && !walletCanMint &&
                            <div> <Link to="/inventory" className="cta" >View collection</Link></div>}
                        <div>{walletCanMint && numMinted < 1 ? 'by clicking the mint button you agree to our ' : ''}</div>
                        <div >
                            <a className={equiv(numMinted, 0) ? "info-link" : "eth"} target="_blank" href='https://docs.google.com/document/d/e/2PACX-1vSUtHPQWr7fYpqalEVZ09xquZfygXqSb3MaxQ-qoKhRwRB8pgqvdP_9C-mzxevNDpotc3Wf-6TIX5a0/pub#h.u9z5qpdy0a2i'>terms of service</a>
                        </div>
                    </div>
                </div>
                <div className="mint-content right">
                    <div className="your-mint blurb">
                        {walletCanMint && readyToMint && <>
                            <div className={`blurb-header ${claiming && 'claiming'} ${!claiming && maxMinting && 'max-minting'}`}>
                                {maxMinting && numToMint > 1 ? 'max' : 'now'} {equiv(amtToPay, 0) ? 'claiming' : 'minting'}
                            </div>
                            <div className="info">
                                {`${readyToMint ? numToMint : ''} skelephunk${numToMint > 1 ? 's' : ''}`}
                            </div>
                            <div className="info">
                                ({genderToMint}/{directionToMint})
                            </div>
                            <div className="info paid">
                                at {cartPrice}
                            </div>
                            <div className="info free">
                                {numFreeMints ? `${numFreeMints} free` : ''}
                            </div>
                            <div className="info eth">
                                {cartTotal}
                            </div>
                        </>}
                    </div>
                </div>
            </div >
        </div >)
}