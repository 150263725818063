//
// Author: Skelephunks 
//
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'


import {
  WagmiProvider, useAccount
} from "wagmi";



import getENV from './util/getENV.js';
import { setLogLevel, LogLevel } from './util/Tracer.js';
import ContractsMenu from './ContractsMenu.js';
import BRB from './BRB.js';
import AppRouter from './AppRouter.js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import defined from './util/defined.js';

setLogLevel(LogLevel.SILENT)

const { status: { network: networkStatus }, override, wagmi: { config: wagmiConfig, chains } } = getENV();

const queryClient = new QueryClient();
const projectId = 'c02eed4383beee4617fbb4d8d2b1a239';


createWeb3Modal({ wagmiConfig, projectId, chains });

export default function App() {
  console.log(`//////////////// STARTING APP /////////////////`)
  return (<>
    <ContractsMenu />
    <div className="home">
      {!networkStatus ?
        <BRB /> :
        <QueryClientProvider client={queryClient}>
          <WagmiProvider config={wagmiConfig}>
            <AppRouter />
          </WagmiProvider>
        </QueryClientProvider>
      }
    </div>
  </>)
}
