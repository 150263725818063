import { useState } from "react";

let timer;
export default function useRedraw() {
    const [num, num_] = useState(0);
    const update = () => {
        console.log(`updating`)
        num_(num + 1);
    }
    const redraw = (force) => {
        clearTimeout(timer);
        if (force) {
            update();
        } else {
            console.log(`deferring`)
            timer = setTimeout(() => {
                update();
            }, 300);//0.1s rolling delay to bundle multiple calls into a single update unless forced earlier
        }
    }
    return {
        update: num,
        redraw
    }
}