import { useState } from "react";
import Grid from "./Grid";
import List from "./List";
import equiv from "./util/equiv";
import useNFTCollection from "./hooks/useNFTCollection";
import defined from "./util/defined";

const BATCH_SIZE = 21;
export default function Collection({ contract, wallet, mode, toggle, hide }) {
    const { collection } = useNFTCollection(contract);
    const [loadAmt, setLoadAmt] = useState(BATCH_SIZE - 1);
    const { balance, tokenIDs } = collection.tokensOfOwner(wallet, loadAmt);
    const [viewMode, setViewMode] = useState(mode || 'grid');
    const loadMore = () => {
        setLoadAmt(loadAmt + BATCH_SIZE);
    }
    const setGridView = () => {
        setViewMode('grid')
    };
    const setListView = () => {
        setViewMode('list')
    };
    if (hide > 0) {
        tokenIDs?.splice(tokenIDs?.indexOf(hide), 1);//remove hidden
    }
    const tokens = tokenIDs?.slice(0, loadAmt);// truncate to load amount
    const visibleBalance = balance - (hide > 0 ? 1 : 0);
    return (<>
        {tokenIDs?.length > 0 && toggle &&
            <div className="toggle">
                <div onClick={setGridView} className={`button ${equiv(viewMode, 'grid') ? 'active' : ''} w-button`}>grid</div>
                <div onClick={setListView} className={`button ${equiv(viewMode, 'list') ? 'active' : ''} w-button`}>list</div>
            </div>
        }
        {equiv(viewMode, 'grid') && <Grid collection={collection} tokenIDs={tokens} balance={visibleBalance} loadMore={loadMore} />}
        {equiv(viewMode, 'list') && <List collection={collection} tokenIDs={tokens} balance={visibleBalance} loadMore={loadMore} />}
    </>);
} 